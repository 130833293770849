body {
  margin: 0;
  padding: 0;
}
.splash-screen {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f2f3f8;
  font-family: Poppins, Helvetica, "sans-serif";
}

.splash-screen img {
  margin-left: calc(100vw - 100%);
  margin-bottom: 30px;
}
